import React from 'react';
import NewsletterSubscriptionModal from './components/NewsletterSubscriptionModal';
import useHandleNewsletterSubscriptionAnswer from './hooks/useHandleNewsletterSubscriptionAnswer';
import useHandleNewsletterModalVisibility from './hooks/useHandleNewsletterModalVisibility';
import NewsletterSubscriptionForm from './components/NewsletterSubscriptionForm';
import Button, { ButtonStyles } from '../../../shared/buttons/Button';

type Props = {
    type?: 'modal' | 'footer';
};

const SubscribeToNewsletter: React.FC<Props> = ({ type = 'footer' }) => {
    const { visible, hideModal } = useHandleNewsletterModalVisibility(!!type);

    const { onValidSubmit, onDecline } = useHandleNewsletterSubscriptionAnswer(hideModal, type);

    if (!visible && type !== 'footer') {
        return null;
    }

    const renderForm = () => (
        <NewsletterSubscriptionForm
            onValidSubmit={onValidSubmit}
            trackingData={`newsletter-subscribe-${type}`}
            highlightButton={false}
        />
    );

    if (type === 'modal') {
        return (
            <NewsletterSubscriptionModal
                onDecline={onDecline}
                title={'Help jij mee de zorg te verbeteren?'}
                // title={'Wil jij op de hoogte blijven van de zorgstandaarden?'}
            >
                <p>
                    {/*                    Geef je, net als duizenden andere ggz-professionals, op voor de 2-wekelijkse nieuwsbrief van Akwa
                    GGZ.*/}
                    Vul de enquête over de zorg voor mensen met psychotrauma of een dissociatieve stoornis in.
                </p>
                {/* {renderForm()} */}
                <a href={'https://evaluatie-zorgstandaarden.nl/'} target="_blank" rel="noreferrer">
                    <Button style={ButtonStyles.primary}>Ja, ik vul de enquête in</Button>
                </a>
            </NewsletterSubscriptionModal>
        );
    }

    return renderForm();
};

export default SubscribeToNewsletter;
